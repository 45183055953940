html {
  background: #dae5df;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

html::-webkit-scrollbar {
  display: none;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 7vmin;
  color: #334b48;
  pointer-events: none;
  position: fixed;
  width: 100vw;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.App-main {
  font-size: 7vmin;
  position: fixed;
  z-index: 2;
  bottom: 1em;
  right: 0;
  width: 100vw;
  display: flex;
  justify-content: space-between;
}

.App-main::before {
  content: "";
}

.App-main::after {
  content: "";
}

.App-footer {
  font-size: 2vmin;
  position: fixed;
  z-index: 2;
  bottom: 0;
  right: 0;
  width: 100vw;
  pointer-events: none;
}

.social-container {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}

.social-container a {
  cursor: pointer;
  pointer-events: all;
}

.social-icon {
  width: 2em;
  margin: 0.5em;
  fill: #334b48;
}

.social-icon:hover {
  fill: #4b826a;
}

canvas {
  display: block;
}

#particles-js {
  width: 100vw;
  height: 100vh;
  background-color: #dae5df;
  background-image: none;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
